<template>
  <a-card
    style="height: 800px;width: 100%;overflow:hidden"
    class="_page-content"
  >
    <div class="department-outer">
      <div class="zoom-box">
        <zoom-controller
          v-model="zoom"
          :min="20"
          :max="200"
        ></zoom-controller>
      </div>
      <div class="view-box">
        <org-view
          :data="data"
          :zoom-handled="zoomHandled"
          @on-menu-click="handleMenuClick"
        ></org-view>
      </div>
      <a-modal
        v-model="isShowDetail"
        destroyOnClose
        title="查看详情"
        onOk="handleOk"
        width="50%"
      >
        <template slot="footer">
          <a-button
            key="back"
            type="primary"
            @click="handleCancel"
          >关闭</a-button>
        </template>
        <ax-form
          ref="formBuilder"
          :formBuilder="type === '0' ? formBuilder : formBuilder1"
        >
        </ax-form>
      </a-modal>
    </div>
  </a-card>
</template>

<script>
import { initGridFormData } from '@/common/tools'
import OrgView from './component/org-tree/org-view.vue'
import ZoomController from './component/org-tree/zoom-controller.vue'
import './component/org-tree/index.less'
import api from './api'
export default {
  name: 'org_tree_page',
  components: {
    OrgView,
    ZoomController
  },
  data () {
    return {
      isShowDetail: false,
      report: {},
      data: {},
      zoom: 100,
      type: '0',
      formBuilder: initGridFormData([
        {
          label: '部门编码',
          model: 'deptNameEn',
          col: { span: 12 }
        },
        {
          label: '部门类型:',
          model: 'deptType',
          type: 'select',
          options: {
            options: [{ label: '内部', value: '0' }, { label: '协作单位', value: '1' }]
          },
          col: { span: 12 }
        }
      ], { layout: 'horizontal', labelWidth: 100, disabled: true }),
      formBuilder1: initGridFormData([
        {
          label: '邮箱',
          model: 'email',
          col: { span: 12 }
        },
        {
          label: '电话:',
          model: 'phone',
          col: { span: 12 }
        }
      ], { layout: 'horizontal', labelWidth: 100, disabled: true })
    }
  },
  computed: {
    zoomHandled () {
      return this.zoom / 100
    }
  },
  methods: {
    handleMenuClick ({ data, key }) {
      if (key === 'detail') {
        this.isShowDetail = true
        this.type = data.type
        this.$nextTick(() => {
          this.$refs.formBuilder.setFieldsValue(data)
        })
      }
    },
    // 会议安排取消按钮
    handleCancel (e) {
      this.isShowDetail = false
    }
  },
  created () {
    api.deptUserTree().then(res => {
      this.data = {
        deptOrder: 'qwer1',
        deptType: '0',
        id: 'qwer1',
        label: '部门及人员',
        parentId: 'qwer1',
        type: '0',
        value: 'qwer1',
        children: res.data
      }
    })
  }
}
</script>
